img.fr-dib {
  position: relative;
  max-width: 100%;
  margin: 5px auto;
  display: block;
  float: none;
  vertical-align: top;

  &.fr-fil {
    margin-left: 0
  }

  &.fr-fir {
    margin-right: 0
  }
}


img.fr-dii {
  position: relative;
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(90%);

  &.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(95%)
  }

  &.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(95%)
  }
}
