$hoverBackground: var(--hover);
$hoverColor: var(--color) !important;
$duration: 0.2s;

/* Sweep To Right */
.hover-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: $duration;
}

.hover-sweep-to-right:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $hoverBackground;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-sweep-to-right:hover,
.hover-sweep-to-right:focus,
.hover-sweep-to-right:active {
  color: $hoverColor;
}

.hover-sweep-to-right:hover:before,
.hover-sweep-to-right:focus:before,
.hover-sweep-to-right:active:before {
  transform: scaleX(1);
}

/* Sweep To Left */
.hover-sweep-to-left {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: $duration;
}

.hover-sweep-to-left:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $hoverBackground;
  transform: scaleX(0);
  transform-origin: 100% 50%;
  transition-property: transform;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-sweep-to-left:hover,
.hover-sweep-to-left:focus,
.hover-sweep-to-left:active {
  color: $hoverColor;
}

.hover-sweep-to-left:hover:before,
.hover-sweep-to-left:focus:before,
.hover-sweep-to-left:active:before {
  transform: scaleX(1);
}

/* Sweep To Bottom */
.hover-sweep-to-bottom {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: $duration;
}

.hover-sweep-to-bottom:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $hoverBackground;
  transform: scaleY(0);
  transform-origin: 50% 0;
  transition-property: transform;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-sweep-to-bottom:hover,
.hover-sweep-to-bottom:focus,
.hover-sweep-to-bottom:active {
  color: $hoverColor;
}

.hover-sweep-to-bottom:hover:before,
.hover-sweep-to-bottom:focus:before,
.hover-sweep-to-bottom:active:before {
  transform: scaleY(1);
}

/* Sweep To Top */
.hover-sweep-to-top {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: $duration;
}

.hover-sweep-to-top:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $hoverBackground;
  transform: scaleY(0);
  transform-origin: 50% 100%;
  transition-property: transform;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-sweep-to-top:hover,
.hover-sweep-to-top:focus,
.hover-sweep-to-top:active {
  color: $hoverColor;
}

.hover-sweep-to-top:hover:before,
.hover-sweep-to-top:focus:before,
.hover-sweep-to-top:active:before {
  transform: scaleY(1);
}

/* Shutter Out Horizontal */
.hover-shutter-out-horizontal {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #e1e1e1;
  transition-property: color;
  transition-duration: $duration;
}

.hover-shutter-out-horizontal:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $hoverBackground;
  transform: scaleX(0);
  transform-origin: 50%;
  transition-property: transform;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-shutter-out-horizontal:hover,
.hover-shutter-out-horizontal:focus,
.hover-shutter-out-horizontal:active {
  color: $hoverColor;
}

.hover-shutter-out-horizontal:hover:before,
.hover-shutter-out-horizontal:focus:before,
.hover-shutter-out-horizontal:active:before {
  transform: scaleX(1);
}

/* Shutter Out Vertical */
.hover-shutter-out-vertical {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #e1e1e1;
  transition-property: color;
  transition-duration: $duration;
}

.hover-shutter-out-vertical:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $hoverBackground;
  transform: scaleY(0);
  transform-origin: 50%;
  transition-property: transform;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-shutter-out-vertical:hover,
.hover-shutter-out-vertical:focus,
.hover-shutter-out-vertical:active {
  color: $hoverColor;
}

.hover-shutter-out-vertical:hover:before,
.hover-shutter-out-vertical:focus:before,
.hover-shutter-out-vertical:active:before {
  transform: scaleY(1);
}

/* Underline From Left */
.hover-underline-from-left {
  display: inline-block;
  vertical-align: middle;

  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.hover-underline-from-left:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: $hoverBackground;
  height: 4px;
  transition-property: right;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-underline-from-left:hover:before,
.hover-underline-from-left:focus:before,
.hover-underline-from-left:active:before {
  right: 0;
}

/* Underline From Center */
.hover-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.hover-underline-from-center:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: $hoverBackground;
  height: 4px;
  transition-property: left, right;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-underline-from-center:hover:before,
.hover-underline-from-center:focus:before,
.hover-underline-from-center:active:before {
  left: 0;
  right: 0;
}

/* Underline From Right */
.hover-underline-from-right {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.hover-underline-from-right:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 100%;
  right: 0;
  bottom: 0;
  background: $hoverBackground;
  height: 4px;
  transition-property: left;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-underline-from-right:hover:before,
.hover-underline-from-right:focus:before,
.hover-underline-from-right:active:before {
  left: 0;
}

/* Overline From Left */
.hover-overline-from-left {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.hover-overline-from-left:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  top: 0;
  background: $hoverBackground;
  height: 4px;
  transition-property: right;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-overline-from-left:hover:before,
.hover-overline-from-left:focus:before,
.hover-overline-from-left:active:before {
  right: 0;
}

/* Overline From Center */
.hover-overline-from-center {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.hover-overline-from-center:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  top: 0;
  background: $hoverBackground;
  height: 4px;
  transition-property: left, right;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-overline-from-center:hover:before,
.hover-overline-from-center:focus:before,
.hover-overline-from-center:active:before {
  left: 0;
  right: 0;
}

/* Overline From Right */
.hover-overline-from-right {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.hover-overline-from-right:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 100%;
  right: 0;
  top: 0;
  background: $hoverBackground;
  height: 4px;
  transition-property: left;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-overline-from-right:hover:before,
.hover-overline-from-right:focus:before,
.hover-overline-from-right:active:before {
  left: 0;
}

/* Underline Reveal */
.hover-underline-reveal {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.hover-underline-reveal:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: $hoverBackground;
  height: 4px;
  transform: translateY(4px);
  transition-property: transform;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-underline-reveal:hover:before,
.hover-underline-reveal:focus:before,
.hover-underline-reveal:active:before {
  transform: translateY(0);
}

/* Overline Reveal */
.hover-overline-reveal {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.hover-overline-reveal:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  background: $hoverBackground;
  height: 4px;
  transform: translateY(-4px);
  transition-property: transform;
  transition-duration: $duration;
  transition-timing-function: ease-out;
}

.hover-overline-reveal:hover:before,
.hover-overline-reveal:focus:before,
.hover-overline-reveal:active:before {
  transform: translateY(0);
}

/* Rotation */
.rotate {
  display: inline-block;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
